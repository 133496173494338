<template>
 <div class="my-box" v-loading="loading">
  <!-- 功能区域 -->
  <el-row>
   <el-card shadow="always">
    <el-form label-width="80px" ref="searchForm" :model="searchForm" :rules="myrules" class="topS">
     <el-row :gutter="10">
      <el-col :span="18">
       <el-form-item label="触发时间" prop="createTime">
        <el-date-picker v-model="searchForm.createTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" @change="changeTime"></el-date-picker>
       </el-form-item>
      </el-col>
      <el-col :span="6">
       <div class="grid-content reset-button">
        <el-button @click="down('searchForm')">导出</el-button>
        <el-button type="primary" @click="search('searchForm')">查询</el-button>
       </div>
      </el-col>
     </el-row>
    </el-form>
   </el-card>
  </el-row>
  <!-- 内容区域 -->
  <el-row>
   <el-card shadow="always">
    <div class="my-header-table">
     <el-table ref="multipleTable" :data="list" tooltip-effect="dark" :height="height" style="width: 100%">
      <el-table-column prop="hotelId" label="酒店ID" width="150"></el-table-column>
      <el-table-column prop="hotelName" label="酒店名"></el-table-column>
      <!-- <el-table-column prop="roomId" label="房间ID" sortable></el-table-column>
            <el-table-column prop="roomNumber" label="房间号" sortable></el-table-column>-->
      <el-table-column prop="count" label="飞房总数" sortable></el-table-column>
     </el-table>
     <el-pagination background layout="prev, pager, next, sizes, total, jumper" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :current-page="pageNo" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
    </div>
   </el-card>
  </el-row>
 </div>
</template>
<script>
import { feifangHotelList, exportfeifangHotelList } from "@/api";
import { getDay } from "@/api/common.js";

export default {
 // name:"feifangStatistics",
 data() {
  return {
   height: null,
   loading: false,
   pageNo: 1,
   pageSize: 10,
   total: 0,
   defaultTime: ["00:00:00", "23:59:59"],
   myrules: {
    createTime: [{ required: true, message: "请选择", trigger: "change" }],
   },
   query: {
    startTime: null,
    endTime: null,
   },
   list: [],
   searchForm: { createTime: null },
  };
 },
 created() {
  this.initTime();
  this.init();
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 240;
 },
 methods: {
  initTime() {
   let startTime = getDay(-30),
    endTime = getDay();
   this.query.startTime = startTime;
   this.query.endTime = endTime;
   this.searchForm.createTime = [startTime, endTime];
  },
  changeTime(val) {
   if (val) {
    this.query.startTime = val[0];
    this.query.endTime = val[1];
   } else {
    this.query.startTime = undefined;
    this.query.endTime = undefined;
   }
  },
  search(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.init();
    } else {
     return false;
    }
   });
  },
  down(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     // this.init()
     exportfeifangHotelList(this.pageNo, this.pageSize, this.query).then(
      (res) => {
       console.log(res);
       var blob = new Blob([res.data], {
        type: "application/vnd.ms-excel",
       });
       var downloadElement = document.createElement("a");
       var href = window.URL.createObjectURL(blob); // 创建下载的链接
       downloadElement.href = href;
       downloadElement.download = "飞房酒店" + ".xlsx"; // 下载后文件名
       document.body.appendChild(downloadElement);
       downloadElement.click(); // 点击下载
       document.body.removeChild(downloadElement); // 下载完成移除元素
       window.URL.revokeObjectURL(href); // 释放掉blob对象
      }
     );
    } else {
     return false;
    }
   });
  },
  init() {
   this.loading = true;
   console.log(this.query);
   feifangHotelList(this.pageNo, this.pageSize, this.query)
    .then((res) => {
     this.loading = false;
     this.total = res.data.data.total;
     this.pageNo = res.data.data.pageNo;
     this.pageSize = res.data.data.pageSize;
     this.list = res.data.data.rows;
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  handleSizeChange(psize) {
   this.pageSize = psize;
   this.init();
  },
  handleCurrentChange(val) {
   this.pageNo = val;
   this.init();
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 .my-iemt {
  span {
   font-size: 14px;
  }

  white-space: nowrap;

  .my-input {
   max-width: 200px;
  }
 }

 .el-row {
  margin-bottom: 10px;

  &:last-child {
   margin-bottom: 0;
  }
 }

 .el-col {
  border-radius: 4px;
 }

 .my-input {
  max-width: 209px;
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;

  span {
   font-size: 14px;
  }
 }

 
}
</style>